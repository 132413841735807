<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>

      <el-input class="filter-item" v-model="listQuery.name" placeholder="名称" style="width: 220px;" clearable/>

      <el-select class="filter-item" v-model="listQuery.is_on" placeholder="上下架状态" style="width: 120px" clearable>
        <el-option label="下架" :value="0" />
        <el-option label="上架" :value="1" />
      </el-select>

      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="ID" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>

      <el-table-column label="上下架状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on" type="success">上架</el-tag>
          <el-tag v-else type="danger">下架</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="200" align="center" fixed="right" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30,50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <!-- 详情 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="150px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="上下架状态" prop="is_on">
          <el-switch v-model="form.is_on" :active-value="1" :inactive-value="0">
          </el-switch>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          school_id: "",
          name: "",
          is_on: "",
        },
        dialogFormVisible: false,
        dialogStatus: "",
        textMap: {
          update: "修改",
          create: "新增",
        },
        btnLoading: false,
        form:{
          id: "",
          school_id: "",
          name: "",
          is_on: 1,
        },
        rules: {
          name: [{ required: true, message: "名称不能为空", trigger: "change" }],
          is_on: [{ required: true, message: "上下架状态不能为空", trigger: "change" }],
        }
      };
    },
    created() {
      this.getList();
    },
    computed: {
      ...mapGetters(["schools","school_id","user"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
      }
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        this.listLoading = true;
        request({
          url: "/api/backend/teacherCardType/list",
          method: "get",
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
          this.listLoading = false;
        });
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.listQuery.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.listQuery.page = val;
        this.getList();
      },
      resetForm() {
        this.form = {
          id: "",
          school_id: "",
          name: "",
          is_on: 1,
        }
        this.btnLoading = false;
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = "create"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dialogStatus = "update"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      saveData() {
        this.form.school_id = this.school_id;
        if (!this.form.school_id) {
          this.$message({
            type: "warning",
            message: "请先选择学校"
          });
          return;
        }

        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: "/api/backend/teacherCardType/save",
              method: "post",
              data: this.form
            }).then(() => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getList();
            }).catch(() => {
              this.btnLoading = false
            })
          }
        })
      },
      handleDelete(row) {
        this.$confirm("确认继续该操作", "提示", {
          type: "warning"
        }).then(() => {
          request({
            url: "/api/backend/teacherCardType/delete",
            method: "post",
            data: {
              id: row.id
            }
          }).then(()=>{
            this.getList();
            this.list.length <= 1 && this.listQuery.page > 1 ? this.listQuery.page-- : false;
            this.$message({
              type: "success",
              message: "操作成功"
            });
          })
        }).catch(() => {});
      },
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
